import React, { useState } from 'react'
import { Box, Slider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const AgePreferences = () => {
    const [ageRange, setAgeRange] = useState([18, 35]); // Default age range

    const handleRangeChange = (event, newValue) => {
      setAgeRange(newValue); // Update the age range state
    };
  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">What Are Your Age Preferences? </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">Please share the age range you prefer for your ideal match.</div>
        <Box sx={{ width: 300, mx: 'auto', textAlign: 'center', mt: 5 }}> 
        <Typography variant="subtitle1" sx={{
          fontSize: '32px',           // Font size 
          color: '#5F6368',              // Text color 
          padding: '8px 16px',        // Padding inside the text 
          display: 'inline-flex',     // Aligns icons and text inline
          alignItems: 'center',       // Centers icons vertically
          fontWeight: 'medium',
        }}>
          {ageRange[0]} <RemoveIcon /> {ageRange[1]} <AddIcon />
      </Typography>
      <Slider
  value={ageRange}
  onChange={handleRangeChange}
  valueLabelDisplay="auto"
  min={18}
  max={70}
  step={1}
  sx={{
    // Main color of the slider
    color: '#916008',
    
    // Styles for the track (filled area of the slider)
    '& .MuiSlider-track': {
      backgroundColor: '#916008', // Track color
      height: '7px',
    },

    // Styles for the thumb (the draggable circle)
    '& .MuiSlider-thumb': {
      backgroundColor: '#BF8500', // Thumb color 
      '&:hover': {
        backgroundColor: '#734f05', // Thumb color on hover
      },
      '&.Mui-active': {
        backgroundColor: '#a07511', // Thumb color when active (dragging)
      },
    },

    // Styles for the rail (unfilled area of the slider)
    '& .MuiSlider-rail': {
      backgroundColor: '#d9d9d9', // Rail color
      height: '7px',
    },

    // Styles for the value label (popup displaying current value)
    '& .MuiSlider-valueLabel': {
      backgroundColor: '#916008', // Value label background color
      color: '#fff',              // Value label text color
    },
  }}
/>

    </Box>
      </div>
    </div>
  )
}

export default AgePreferences
