import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { jarallax } from 'jarallax';
import 'jarallax/dist/jarallax.min.css'; // Importing jarallax CSS

const ReadyToExperience = () => {
  const videoRef = useRef(null); // Ref for video element
  const [isInView, setIsInView] = useState(false); // State to track if the video is in view

  // Use IntersectionObserver to detect if the video is in the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsInView(true); // Video is in view, so start loading
        }
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current); // Observe the video section
    }

    // Cleanup observer when component unmounts
    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      // Initialize jarallax once the video is in view
      const elements = document.querySelectorAll('.jarallax');
      jarallax(elements, {
        videoSrc: '/assets/video/0_Couple_Sunset_3840x2160.mp4', // Correct video path
        videoVolume: 0.5, // Adjust video volume if necessary
        videoAutoplay: true, // Video autoplay
        videoPlayOnlyVisible: true, // Video only plays when visible
      });
    }
  }, [isInView]);

  return (
    <div
      className="ReadytoExperience jarallax py-120"
      ref={videoRef}
      data-jarallax
      data-speed="0.2"
      data-video-src="/assets/video/0_Couple_Sunset_3840x2160.mp4" // Path to your video in the public folder
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      {/* Video will only load once it's in view */}
      {isInView && (
        <video
          autoPlay
          loop
          muted
          style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 0, width: '100%' }}
        >
          <source src="/assets/video/0_Couple_Sunset_3840x2160.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {/* Content */}
      <div className="text-center" style={{ position: 'relative', zIndex: 2 }}>
        <h2 className="font-40 fw-bold mw-600-px text-black mx-auto">
          Ready to Experience the Luxury of True Connections?
        </h2>
        <div className="mt-32">
          <Button
            className="btn gradient-btn font-20 fw-bold font-m text-black rounded-pill py-10 px-20 "
            endIcon={<EastIcon />}
          >
            Sign Up for Free
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReadyToExperience
