import React, { useState } from 'react'

const YourIncomeRange = () => {
    const [incomeRange, setIncomeRange] = useState("");
    
    const handleIncomeRange = (value) => {
        setIncomeRange(value);
        console.log("Selected Income Range:", value);
      };
  return (
    <div>
    <div className="">
     <div className="font-m font-32 fw-medium text-center">Select Your Income Range</div>
     <div className="font-m font-18 colorf5 pt-6 text-center">
     Choose the income range that best reflects your lifestyle and preferences. 
     </div>
   </div> 
   <div className="incomeRange-selection text-center"> 
     <div className="btnsetAll btnsetAll2 mt-6">  
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "Below $100,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("Below $100,000")}>Below $100,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$100,000 - $150,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$100,000 - $150,000")}>$100,000 - $150,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$150,000 - $200,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$150,000 - $200,000")}>$150,000 - $200,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$200,000 - $250,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$200,000 - $250,000")}>$200,000 - $250,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$250,000 - $300,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$250,000 - $300,000")}>$250,000 - $300,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$300,000 - $350,00" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$300,000 - $350,00")}>$300,000 - $350,00</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$350,000 - $400,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$350,000 - $400,000")}> $350,000 - $400,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$400,000 - $450,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$400,000 - $450,000")}>$400,000 - $450,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$450,000 - $500,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$450,000 - $500,000")}>$450,000 - $500,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$500,000 - $600,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$500,000 - $600,000")}>$500,000 - $600,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$600,000- $700,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$600,000- $700,000")}> $600,000- $700,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$700,000 - $800,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$700,000 - $800,000")}>$700,000 - $800,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$800,000 - $900,000" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$800,000 - $900,000")}>$800,000 - $900,000</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "$900,000 - $1 million" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("$900,000 - $1 million")}>$900,000 - $1 million</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "More Than $1 Million" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("More Than $1 Million")}>More Than $1 Million</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "More Than $2 Million" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("More Than $2 Million")}>More Than $2 Million</button>
             <button
             className={`font-m border font-16 fw-normal ${incomeRange === "More Than $5 Million" ? "selected" : ""}`}
             onClick={() => handleIncomeRange("More Than $5 Million")}>More Than $5 Million</button>    
     </div> 
   </div>
 </div>
  )
}

export default YourIncomeRange
