import React, { useState } from 'react';

const YourEthnicity = () => {
  const [ethnicity, setEthnicity] = useState('');

  const ethnicityOptions = [
    'Asian',
    'Black/African descent',
    'Hispanic/Latino',
    'Middle Eastern',
    'Native American/Indigenous',
    'Pacific Islander',
    'White/Caucasian',
    'Mixed/Multiracial',
    'Other',
    'Prefer not to say',
  ];

  const handleEthnicity = (value) => {
    setEthnicity(value);
    console.log('Selected Ethnicity:', value);
  };

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">What’s Your Ethnicity?</div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Let your matches get to know you better by sharing your background.
        </div>
      </div>
      <div className="ethnicity-selection text-center">
        <h3 className="font-18 font-m fw-medium mt-24 mb-0">Choose Your Ethnicity</h3>
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render ethnicity options */}
          {ethnicityOptions.map((option) => (
            <button
              key={option}
              className={`font-m border font-16 fw-normal ${ethnicity === option ? 'selected' : ''}`}
              onClick={() => handleEthnicity(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourEthnicity