import React from 'react'; 

const PasswordResetPopup = () => {
  return (
     
<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Trouble Resetting Your Password?</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <p>If you didn’t receive the password reset link or are having trouble, try one of the following options:</p>
          <ol>
            <li>Check Your Spam/Junk Folder: Sometimes, our emails can end up in your spam folder. Make sure to check there!</li>
            <li>Resend the Reset Link: If you still haven’t received the email, click below to resend the password reset link to your inbox.</li>
            <li>Contact Support: If you’re still experiencing issues, feel free to contact our support team for further assistance.</li>
          </ol>
          <div className="popup-actions">
            <button onClick={() => alert("Resend Link Clicked")}>Resend Link</button>
            <button onClick={() => window.location.href = "mailto:support@luxurylife.ai"}>Contact Support</button>
            <button>Try Another Way</button>
          </div>
      </div>
      <div className="modal-footer">
      <button className="complete-verification-btn">Complete Verification</button>
      </div>
    </div>
  </div>
</div> 
  );
};

export default PasswordResetPopup;
