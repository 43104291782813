import React, { useState } from 'react'

const NetWorth = () => {
    const [netWorth, setNetWorth] = useState("");
    
    const handleNetWorth = (value) => {
        setNetWorth(value);
        console.log("Selected Net Worth:", value);
      };
  return (
    <div>
    <div className="">
     <div className="font-m font-32 fw-medium text-center">What Is Your Net Worth? </div>
     <div className="font-m font-18 colorf5 pt-6 text-center">
     Enter the approximate amount that best reflects your current financial standing.
     </div>
   </div> 
   <div className="netWorth-selection text-center"> 
     <div className="btnsetAll btnsetAll2 mt-6">  
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "Below $200,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("Below $200,000")}>Below $200,000</button> 
              <button
             className={`font-m border font-16 fw-normal ${netWorth === "$200,000 - $300,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$200,000 - $300,000")}>$200,000 - $300,000</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$300,000 - $400,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$300,000 - $400,000")}>$300,000 - $400,000</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$400,000 - $500,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$400,000 - $500,000")}>$400,000 - $500,000</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$500,000 - $600,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$500,000 - $600,000")}>$500,000 - $600,000</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$600,000 - $700,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$600,000 - $700,000")}>$600,000 - $700,000</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$700,000 - $800,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$700,000 - $800,000")}>$700,000 - $800,000</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$800,000 - $900,000" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$800,000 - $900,000")}>$800,000 - $900,000</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "$900,000 - $1 million" ? "selected" : ""}`}
             onClick={() => handleNetWorth("$900,000 - $1 million")}>$900,000 - $1 million</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "More Than $1 Million" ? "selected" : ""}`}
             onClick={() => handleNetWorth("More Than $1 Million")}>More Than $1 Million</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "More Than $2 Million" ? "selected" : ""}`}
             onClick={() => handleNetWorth("More Than $2 Million")}>More Than $2 Million</button> 
             <button
             className={`font-m border font-16 fw-normal ${netWorth === "More Than $5 Million" ? "selected" : ""}`}
             onClick={() => handleNetWorth("More Than $5 Million")}>More Than $5 Million</button> 
                  
     </div> 
   </div>
 </div>
  )
}

export default NetWorth
