import React, { useState } from "react";

const SecurityQuestionSetup = () => {
  const [questions] = useState([
    "What is your favorite color?",
    "What is your mother's maiden name?",
    "What was your first pet's name?",
    "What is your hometown?",
    "What was your first car?"
  ]);

  const [answers, setAnswers] = useState({
    question1: "",
    answer1: "",
    question2: "",
    answer2: "",
    question3: "",
    answer3: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers({ ...answers, [name]: value });
  };

  return (
    <div className="security-setup-container mw-570-px mx-auto btnsWithConnects">  
                    <div className="">
     <div className="font-m font-32 fw-medium text-center">Set Up Your Security Question</div>
      <div className="font-m font-14 colorf5 pt-6 text-center">
      To help secure your account, please select three security questions and
        provide answers. This will be used to verify your identity in case you
        need to reset your password later.
      </div>
     </div>

     
      <form className="security-form pt-24">
        <div className="font-20 font-m fw-medium mb-16 text-center">Choose 3 Security Question:</div>
        {[1, 2, 3].map((num) => (
          <div className="security-row d-flex gap-3" key={num}>
            <select className="form-select mb-10 mw-200-px"
              name={`question${num}`}
              value={answers[`question${num}`]}
              onChange={handleChange}
              required
            >
              <option value="">Select Question {num}</option>
              {questions.map((question, index) => (
                <option key={index} value={question}>
                  {question}
                </option>
              ))}
            </select>
            <input className="form-control mb-10"
              type="text"
              name={`answer${num}`}
              placeholder="Type your answer here"
              value={answers[`answer${num}`]}
              onChange={handleChange}
              required
            />
          </div>
        ))}
      </form>
    </div>
  );
};

export default SecurityQuestionSetup