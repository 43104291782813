import { Container } from '@mui/material'
import React from 'react'

const LuxuryText = () => {
  const textData = [
    { letter: 'L', word: 'Loyalty' },
    { letter: 'U', word: 'Uniqueness' },
    { letter: 'X', word: 'Exclusivity' },
    { letter: 'U', word: 'Understanding' },
    { letter: 'R', word: 'Relationships' },
    { letter: 'Y', word: 'Your Privacy, Your Choice' }
  ];

  return (
    <div className="bg-white position-relative z-3 py-44 luxletterText-Sec" data-bgcolor="light">
      <Container maxWidth="xl">
        <div className="row">
          <div className="col-md-12 text-center d-flex justify-content-between align-items-start luxletterText">
            {textData.map((item, index) => (
              <div key={index} className="text-center luxuryText">
                <span className="firstText font-48 fw-bold text-gold">{item.letter}</span>
                <div className="text text-black font-14 fw-bold font-m text-uppercase">{item.word}</div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default LuxuryText
