import React, { useState } from "react";

const GenderInterestSelection = () => {
  const [gender, setGender] = useState("");
  const [interests, setInterests] = useState([]);

  const handleGenderSelection = (value) => {
    setGender(value);
    console.log("Selected Gender:", value);
  };

  const handleInterestSelection = (value) => {
    if (interests.includes(value)) {
      setInterests(interests.filter((interest) => interest !== value));
    } else {
      setInterests([...interests, value]);
    }
    console.log("Selected Interests:", interests);
  };

  return (
    <>
      <div className="header text-center">
        <div className="font-p font-36 text-g fst-italic fw-bold text-gold-light">
          Welcome to Luxury Life  <span className="font-16 fw-medium font-m text-black d-block fst-normal">
          Where Ambition Meets Affluence
        </span>
        </div> 
        <div className="font-14 fw-normal font-m colorf5 mt-6 mb-36">
          Let’s get to know you better to create your personalized luxury dating experience.
        </div>
      </div>
<div className="GISec">
      {/* Gender Selection */}
      <div className="gender-selection text-center">
        <h3 className="font-24 font-m fw-medium mb-0">What is your gender?</h3>
        <div className="btnsetAll mt-6">
          <button
            className={`font-m border font-16 fw-normal ${gender === "Male" ? "selected" : ""}`}
            onClick={() => handleGenderSelection("Male")}
          >
            Male
          </button>
          <button
            className={`font-m border font-16 fw-normal ${gender === "Female" ? "selected" : ""}`}
            onClick={() => handleGenderSelection("Female")}
          >
            Female
          </button>
          <button
            className={`font-m border font-16 fw-normal ${gender === "Other" ? "selected" : ""}`}
            onClick={() => handleGenderSelection("Other")}
          >
            Other
          </button>
        </div>
      </div>

      {/* Interest Selection */}
      <div className="interest-selection text-center">
        <h3 className="font-24 font-m fw-medium mb-0">Who are you interested in?</h3>
        <div className="btnsetAll mt-6">
          <button
            className={`font-m border font-16 fw-normal ${interests.includes("Men") ? "selected" : ""}`}
            onClick={() => handleInterestSelection("Men")}
          >
            Men
          </button>
          <button
            className={`font-m border font-16 fw-normal ${interests.includes("Women") ? "selected" : ""}`}
            onClick={() => handleInterestSelection("Women")}
          >
            Women
          </button>
          <button
            className={`font-m border font-16 fw-normal ${interests.includes("Non-binary/Other") ? "selected" : ""}`}
            onClick={() => handleInterestSelection("Non-binary/Other")}
          >
            Non-binary/Other
          </button>
          <button
            className={`font-m border font-16 fw-normal ${interests.includes("Both") ? "selected" : ""}`}
            onClick={() => handleInterestSelection("Both")}
          >
            Both
          </button>
        </div>
      </div>
      </div>
    </>
  );
};

export default GenderInterestSelection;
