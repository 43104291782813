import React, { useState, useLayoutEffect, useRef } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const HeightSelector = () => {
  const [unit, setUnit] = useState("cm");
  const [height, setHeight] = useState(174);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const scaleRef = useRef(null);

  useLayoutEffect(() => {
    // Ensure the element is rendered before accessing its offsetWidth
    if (scaleRef.current && scaleRef.current.children[activeIndex]) {
      const scaleWidth = scaleRef.current.offsetWidth;
      const valueWidth = scaleRef.current.children[activeIndex].offsetWidth;
      const offset = (scaleWidth / 2) - (valueWidth / 2);
      scaleRef.current.scrollLeft = scaleRef.current.children[activeIndex].offsetLeft - offset;
    }
  }, [activeIndex]); // Re-run effect when activeIndex changes

  const handleUnitToggle = (selectedUnit) => {
    setUnit(selectedUnit);
    if (selectedUnit === "cm") {
      setHeight(174);
      setCurrentIndex(0);
      setActiveIndex(24);
    } else {
      setHeight(5.8);
      setCurrentIndex(0);
      setActiveIndex(24);
    }
  };

  const handleHeightChange = (value, index) => {
    setHeight(value);
    setActiveIndex(index);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, scaleValues.length - 5));
  };

  const scaleValues = unit === "cm"
    ? Array.from({ length: 11 }, (_, i) => 150 + i * 1)
    : Array.from({ length: 11 }, (_, i) => (4.5 + i * 0.2).toFixed(1));

  const visibleValues = scaleValues.slice(currentIndex, currentIndex + 5);

  return (
    <div className="height-selector">
        <div className="">
                    <div className="font-m font-32 fw-medium text-center">How Tall Are You?</div>
                    <div className="font-m font-14 colorf5 pt-6 text-center">
                    Let your potential matches know your height.
                    </div>
                </div>
       

      <div className="unit-toggle mt-32">
        <button
          className={unit === "cm" ? "active" : ""}
          onClick={() => handleUnitToggle("cm")}
        >
          CM
        </button>
        <button
          className={unit === "feet" ? "active" : ""}
          onClick={() => handleUnitToggle("feet")}
        >
          Feet/Inches
        </button>
      </div>

      <div className="height-display">
        {unit === "cm" ? `${height} cm` : `${parseFloat(height).toFixed(1)} ft`}
      </div>
      <div className="slider-container">
        <button className="slider-btn" onClick={handlePrev}><KeyboardArrowLeftIcon /></button>

        <div className="slider-scale" ref={scaleRef}>
          <div className="scale-values">
            {visibleValues.map((value, index) => (
              <span
                key={value}
                className={`scale-value ${parseFloat(value) === parseFloat(height) ? "active" : ""}`}
                onClick={() => handleHeightChange(parseFloat(value), index + currentIndex)}
              >
                {value} {unit}
              </span>
            ))}
          </div>
        </div>

        <button className="slider-btn" onClick={handleNext}><KeyboardArrowRightIcon /></button>
      </div>
    </div>
  );
};

export default HeightSelector;
