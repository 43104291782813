import React, { useState } from 'react';
import { TextField, Box, Typography, Button, Link } from '@mui/material';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);
    // Handle form submission logic here
  };

 

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,  
        margin: 'auto',
      }}
    >
      <TextField
        placeholder="Email"
        type="email"
        autoComplete="off"
        variant="outlined"
        fullWidth
        required
        value={email}
        onChange={handleEmailChange}
        sx={{
          height: '44px',
          overflow: 'hidden',
          borderRadius: '15px',
          '& .MuiOutlinedInput-root': {
            height: '44px',
            borderRadius: '15px',
            '& fieldset': {
              borderColor: '#ccc',
            },
            '&:hover fieldset': {
              borderColor: '#916008',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#916008',
              borderWidth: '1px',
            },
          },
        }}
      />
      <TextField
        placeholder="Password"
        type="password"
        autoComplete="off"
        variant="outlined"
        fullWidth
        required
        value={password}
        onChange={handlePasswordChange}
        sx={{
          height: '44px',
          overflow: 'hidden',
          borderRadius: '15px',
          '& .MuiOutlinedInput-root': {
            height: '44px',
            borderRadius: '15px',
            '& fieldset': {
              borderColor: '#ccc',
            },
            '&:hover fieldset': {
              borderColor: '#916008',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#916008',
              borderWidth: '1px',
            },
          },
        }}
      /> 
    </Box>
  );
};

export default LoginForm;
