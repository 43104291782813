import React, { useState } from 'react';

const RelationshipStatus = () => {
  const [relationship, setRelationship] = useState('');

  const relationshipOptions = [
    'Single',
    'In a Relationship',
    'Married',
    'Divorced',
    'Widowed',
    'Prefer not to say',
  ];

  const handleRelationship = (value) => {
    setRelationship(value);
    console.log('Selected Relationship Status:', value);
  };

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">
          What is Your Current Relationship Status?
        </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Please let us know your relationship status to help us match you with
          compatible individuals.
        </div>
      </div>
      <div className="relationship-selection text-center">
        <h3 className="font-18 font-m fw-medium mt-24 mb-0">Choose Your Relationship Status</h3>
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render relationship status options */}
          {relationshipOptions.map((status) => (
            <button
              key={status}
              className={`font-m border font-16 fw-normal ${relationship === status ? 'selected' : ''}`}
              onClick={() => handleRelationship(status)}
            >
              {status}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelationshipStatus