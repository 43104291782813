import React, { useState } from 'react';

const DoYouDrinkAlcohol = () => {
  const [drinkAlcohol, setDrinkAlcohol] = useState("");

  // Define the options dynamically
  const options = ["Yes", "No", "Prefer not to say"];

  const handleDrinkAlcohol = (value) => {
    setDrinkAlcohol(value);
    console.log("Selected Drink Alcohol Option:", value);
  };

  return (
    <div>
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Do You Drink Alcohol? </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Let us know if you drink alcohol so we can better match you with individuals who have similar preferences.
        </div>
      </div>
      <div className="DrinkAlcohol-selection text-center">
        <h3 className="font-18 font-m fw-medium mt-24 mb-0">Choose One</h3>
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render buttons */}
          {options.map((option, index) => (
            <button
              key={index}
              className={`font-m border font-16 fw-normal ${drinkAlcohol === option ? "selected" : ""}`}
              onClick={() => handleDrinkAlcohol(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoYouDrinkAlcohol;
