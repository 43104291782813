import React, { useState } from 'react';

const YourField = () => {
  const [yourfield, setYourField] = useState("");

  const fieldOptions = [
    'Finance/Investment',
    'Technology/Software',
    'Arts/Entertainment',
    'Healthcare/Medical',
    'Law/Legal',
    'Education',
    'Marketing/Sales',
    'Hospitality/Real Estate',
    'Entrepreneurship/Startups',
    'Other',
    'Prefer not to say'
  ];

  const handleYourField = (value) => {
    setYourField(value);
    console.log("Selected yourfield:", value);
  };

  return (
    <div>
      <div className="">
        <div className="font-m font-32 fw-medium text-center">What Field Do You Work In?</div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Let us know your profession to help us match you with like-minded individuals.
        </div>
      </div>
      <div className="yourfield-selection text-center">
        <h3 className="font-18 font-m fw-medium mt-24 mb-0">Choose Your Field</h3>
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render field options */}
          {fieldOptions.map((option) => (
            <button
              key={option}
              className={`font-m border font-16 fw-normal ${yourfield === option ? "selected" : ""}`}
              onClick={() => handleYourField(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YourField;
