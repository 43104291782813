import React, { useRef, useEffect, useState } from "react";
import { Container, Button } from "@mui/material";
import { Link } from "react-router-dom";

const VideoBanner = () => {
  const videoRef = useRef(null);

  const [bannerContent, setBannerContent] = useState(() => {
    const savedData = localStorage.getItem("bannerContent");
    return savedData
      ? JSON.parse(savedData)
      : {
          videoSrc: "/assets/video/banner-video.mp4",
          posterSrc: "/assets/images/poster-banner.png",  // Ensure path is correct
          title: "Where Ambition Meets",
          subtitle: "Affluence",
          description:
            "Connecting beautiful, ambitious women with successful, affluent men in a secure and exclusive environment.",
          buttonText: "Join the Exclusive Community",
          barcodeSrc: "/assets/images/barcode.png",
          appText: "Get the app!",
          googlePlayImg: "/assets/images/google-play.png",
        };
  });

  useEffect(() => {
    localStorage.setItem("bannerContent", JSON.stringify(bannerContent));
  }, [bannerContent]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.currentTime = 0;
    }
  }, []);

  const updateBannerContent = () => {
    setBannerContent((prev) => ({
      ...prev,
      title: "Where Dreams Meet Reality",
    }));
  };

  return (
    <div className="banner homebanner-Sec" id="previous-section">
      <video
        className="banner-video"
        autoPlay
        loop
        muted
        ref={videoRef}
        poster={bannerContent.posterSrc} // Ensure posterSrc is passed correctly
        onLoadedData={() => {
          console.log("Video and poster loaded");
        }}
      >
        <source src={bannerContent.videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <Container maxWidth="xl" className="banner-content gap-5">
        <div className="text-start">
          <h1 className="font-64 animated-text font-m">
            {bannerContent.title}{" "}
            <span className="text-italic font-p fw-900 animated-text-delayed">
              {bannerContent.subtitle}
            </span>
          </h1>
          <div className="d-flex align-items-center gap-4 bannerContentPBtn">
            <p className="font-20 text-white mw-400-px animated-text-delayed  font-m">
              {bannerContent.description}
            </p>
            <Button
              className="btn-output fw-bold rounded-pill px-20 text-nowrap"
              onClick={updateBannerContent}
            >
              {bannerContent.buttonText}
            </Button>
          </div>
        </div>
        <div>
          <div className="mb-4 Barcode">
            <img src={bannerContent.barcodeSrc} className="img-fluid" alt="Barcode" />
          </div>
          <div className="d-flex align-items-center gap-4 text-nowrap fw-bold font-20">
            {bannerContent.appText}{" "}
            <Link>
              <img
                src={bannerContent.googlePlayImg}
                className="img-fluid googlePlayBtn"
                alt="Google Play"
              />
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VideoBanner;
