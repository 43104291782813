import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';

const FewWords = () => {
    const [text, setText] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleTextChange = (event) => {
      const value = event.target.value;
      setText(value);
      setCharCount(value.length); // Count characters
      if (value.length > 0) {
        setErrorMessage(''); // Clear the error message if text is typed
      }
    };
  
    const handleBlur = () => {
      if (text.trim() === '') {
        setErrorMessage('You are missing your description.');
      }
    };
  return (
    <div>
    <div className="font-m font-32 fw-medium text-center">Describe Yourself in a Few Words </div>
    <div className="font-m font-18 colorf5 pt-6 text-center">
    This is your chance to let others know what makes you unique. Share a brief description of your personality, interests, and what you’re looking for in a partner.
    </div> 

    <TextField
      placeholder="Create Your Personal Intro"
      variant="outlined"
      multiline
      rows={4}
      fullWidth
      value={text}
      onChange={handleTextChange}
      onBlur={handleBlur} // Trigger when the field loses focus
      inputProps={{ maxLength: 4000 }}  // Limit to 4000 characters
      sx={{
        marginTop: 2,
        '& .MuiOutlinedInput-root': {
          borderRadius: '15px',
        },
        
        '& .MuiOutlinedInput-root': {
          
          borderRadius: '15px',
          '& fieldset': {
            borderColor: '#ccc',
          },
          '&:hover fieldset': {
            borderColor: '#916008',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#916008',
            borderWidth: '1px',
          },
        },
      }}
    />
    
   <div className='d-flex justify-content-between align-items-start'>  
    {/* Show error message if the field is empty when it loses focus */}
   <div className=""> {errorMessage && (
      <Typography variant="body2" color="error" align="center" sx={{ marginTop: 1 }}>
        {errorMessage}
      </Typography>
    )}</div>
    <Typography variant="body2" color="textSecondary" align="right" sx={{ marginTop: 1 }}>
      {charCount} / 4000 characters
    </Typography>
    </div> 
  </div>
  )
}

export default FewWords
