import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';

const YouLookingForPartner = () => {
  const [text, setText] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const handleTextChange = (event) => {
    const value = event.target.value;
    setText(value);
    setCharCount(value.length); // Count characters
    if (value.length > 0) {
      setErrorMessage(''); // Clear the error message if text is typed
    }
  };

  const handleBlur = () => {
    if (text.trim() === '') {
      setErrorMessage('You are missing your what you are looking for.');
    }
  };

  return (
    <div>
      <div className="font-m font-32 fw-medium text-center">What Are You Looking for in a Partner?  </div>
      <div className="font-m font-18 colorf5 pt-6 text-center">
      Tell us more about the qualities and traits you desire in a partner. Whether you're looking for someone with shared interests, a similar lifestyle, or specific values, this will help us recommend the best matches.
      </div> 

      <TextField
        placeholder="Describe Your Ideal Partner"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        value={text}
        onChange={handleTextChange}
        onBlur={handleBlur} // Trigger when the field loses focus
        inputProps={{ maxLength: 4000 }}  // Limit to 4000 characters
        
        sx={{ 
            marginTop: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: '15px',
          },
            '& .MuiOutlinedInput-root': {
               
              borderRadius: '15px',
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#916008',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#916008',
                borderWidth: '1px',
              },
            },
          }}
      />
      
     <div className='d-flex justify-content-between align-items-start'>  
      {/* Show error message if the field is empty when it loses focus */}
     <div className=""> {errorMessage && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: 1 }}>
          {errorMessage}
        </Typography>
      )}</div>
      <Typography variant="body2" color="textSecondary" align="right" sx={{ marginTop: 1 }}>
        {charCount} / 4000 characters
      </Typography>
      </div> 
    </div>
  );
};

export default YouLookingForPartner