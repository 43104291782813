import { Container } from "@mui/material";
import React, { useState, useEffect } from "react";

const sections = [
  {
    id: "section-1",
    title: "Discreet Connections",
    description: "Advanced privacy settings to control your visibility.",
    imgSrc: "/assets/images/discreet-connections.png",
    altText: "Discreet Connections",
  },
  {
    id: "section-2",
    title: "Luxury Matchmaking",
    description: "AI-powered to connect beauty with ambition.",
    imgSrc: "/assets/images/luxury-matchmaking.png",
    altText: "Luxury Matchmaking",
  },
  {
    id: "section-3",
    title: "Real Profiles",
    description: "Ensuring you get real profiles as recommendations.",
    imgSrc: "/assets/images/real-profiles.png",
    altText: "Real Profiles",
  },
  {
    id: "section-4",
    title: "Exclusive Events",
    description: "Access private events for networking and relationship building.",
    imgSrc: "/assets/images/exclusive-events.jpg",
    altText: "Exclusive Events",
  },
];

const YourExclusive = () => {
  const [activeSection, setActiveSection] = useState(null);

  // Handle smooth scrolling
  const handleSmoothScroll = (e, target) => {
    e.preventDefault();
    document.querySelector(target).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Update active section on scroll
  useEffect(() => {
    const handleScroll = () => {
      const scrollDistance = window.scrollY;
      document.querySelectorAll(".page-section").forEach((section, index) => {
        if (section.offsetTop <= scrollDistance + 250) {
          setActiveSection(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup event listener
  }, []);

  return (
    <div className="py-44 Exclusive-Sec" style={{ background: "#000" }}>
      <Container maxWidth="xl">
        <div className="col-md-12 text-center pb-44">
          <h2 className="text-white text-center font-m fw-bold">
            Your{" "}
            <span className="font-p font-64 fst-italic text-g">Exclusive</span>{" "}
            Matchmaking Experience
          </h2>
        </div>
        <div className="d-flex justify-content-between align-items-start">
          <nav className="navigation Exclusivetext stickyTop Scroll-none" id="mainNav">
            {sections.map((section, index) => (
              // <a
              //   key={section.id}
              //   className={`thumbnailText thumbnailimg1 text-center py-16 navigation__link ${
              //     activeSection === index ? "active" : ""
              //   }`}
              //   href={`#${section.id}`}
              //   onClick={(e) => handleSmoothScroll(e, `#${section.id}`)}
              // >
              //   <h2 className="font-48 font-m fw-bold text-uppercase">
              //     {section.title}
              //   </h2>
              //   <p className="font-24 font-m fw-normal">{section.description}</p>
              // </a>
              <a
  key={section.id}
  className={`thumbnailText text-center py-16 navigation__link ${
    activeSection === index ? "active" : ""
  } thumbnailimg${index + 1}`}
  href={`#${section.id}`}
  onClick={(e) => handleSmoothScroll(e, `#${section.id}`)}
>
  <h2 className="font-48 font-m fw-bold text-uppercase">
    {section.title}
  </h2>
  <p className="font-24 font-m fw-normal">{section.description}</p>
</a>

            ))}
          </nav>
          <div className="ExclusiveImg mainImages">
            {sections.map((section) => (
              <div
                key={section.id}
                className="page-section hero py-32"
                id={section.id}
              >
                <div className={`img img${section.id}`}>
                  <img
                    src={section.imgSrc}
                    className="img-fluid"
                    alt={section.altText}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default YourExclusive;
