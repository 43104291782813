import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';

const CreateCatchyHeading = () => {
    const [text, setText] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleTextChange = (event) => {
      const value = event.target.value;
      setText(value);
      setCharCount(value.length); // Count characters
      if (value.length > 0) {
        setErrorMessage(''); // Clear the error message if text is typed
      }
    };
  
    const handleBlur = () => {
      if (text.trim() === '') {
        setErrorMessage('You are missing your heading.');
      }
    };
  return (
    <div>
    <div className="font-m font-32 fw-medium text-center">Create a Catchy Heading for Your Profile </div>
    <div className="font-m font-18 colorf5 pt-6 text-center">
    Your headline is the first thing people will see – make it count! Share something unique about yourself or a fun fact that captures your essence.
    </div> 

    <TextField
      placeholder="Example Ideas: Adventure seeker looking for my next journey."
      variant="outlined"  
      fullWidth
      value={text}
      onChange={handleTextChange}
      onBlur={handleBlur} // Trigger when the field loses focus
      inputProps={{ maxLength: 50 }}  // Limit to 50 characters
      
      sx={{
        marginTop: 2,
        '& .MuiOutlinedInput-root': {
          borderRadius: '15px',
        },
        height: '44px',
        '& .MuiOutlinedInput-root': {
          height: '44px',
          borderRadius: '15px',
          '& fieldset': {
            borderColor: '#ccc',
          },
          '&:hover fieldset': {
            borderColor: '#916008',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#916008',
            borderWidth: '1px',
          },
        },
      }}
    />
    
   <div className='d-flex justify-content-between align-items-start'>  
    {/* Show error message if the field is empty when it loses focus */}
   <div className=""> {errorMessage && (
      <Typography variant="body2" color="error" align="center" sx={{ marginTop: 1 }}>
        {errorMessage}
      </Typography>
    )}</div>
    <Typography variant="body2" color="textSecondary" align="right" sx={{ marginTop: 1 }}>
      {charCount} / 50 characters
    </Typography>
    </div> 
  </div>
  )
}

export default CreateCatchyHeading
