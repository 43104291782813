import React, { useState } from 'react';

const HaveChildren = () => {
  const [haveChildren, setHaveChildren] = useState("");

  // Define the options dynamically
  const options = [
    "Yes, I have children",
    "No, I do not have children",
    "Prefer not to say"
  ];

  const handleHaveChildren = (value) => {
    setHaveChildren(value);
    console.log("Selected HaveChildren:", value);
  };

  return (
    <div>
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Do You Have Children? </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Let us know if you have children to help us find matches with similar family values and preferences.
        </div>
      </div>
      <div className="HaveChildren-selection text-center">
        <h3 className="font-18 font-m fw-medium mt-24 mb-0">Select Your Option</h3>
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render buttons */}
          {options.map((option, index) => (
            <button
              key={index}
              className={`font-m border font-16 fw-normal ${haveChildren === option ? "selected" : ""}`}
              onClick={() => handleHaveChildren(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HaveChildren