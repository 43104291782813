import React, { useState } from "react";

const UserName = () => {
    const [username, setUsername] = useState(""); // State to track input value
    const takenUsernames = ["johndoe25", "janedoe12"]; // Example list of taken usernames

    const handleInputChange = (e) => {
        setUsername(e.target.value); // Update the state on input change
    };

    // Check if the username is taken
    const isUsernameTaken = takenUsernames.includes(username.trim());

    return (
        <div className="mw-500-px btnsWithConnects mx-auto">
            
                <div className="">
                    <div className="font-m font-32 fw-medium text-center">What Name Do You Want To Use?</div>
                    <div className="font-m font-14 colorf5 pt-6 text-center">
                        In case of privacy, you can choose to display your username instead of your full name.
                    </div>
                </div>
<div className="mw-425-px mx-auto">
                <div className="icon-field mt-32 mx-auto position-relative psistetVefid w-100">
                    <span className="icon font-16" style={{top: '8px'}}>
                        &#64;
                    </span>
                    <input
                        className="form-control mt-36"
                        type="text"
                        placeholder="Enter username"
                        required
                        value={username}
                        onChange={handleInputChange}
                    />
                    {!isUsernameTaken && username && (
                        <span className="correct f14-mvw">
                            <img
                                src="/assets/images/signup/otp-verified.svg"
                                className="img-fluid"
                                alt="Verified"
                            />
                        </span>
                    )}
                </div>
                {/* Example text visible only when the input is empty */}
                {!username && (
                    <div className="text-start exmple f12-nvw">eg. johndoe25</div>
                )}
                {isUsernameTaken && (
                    <>
                        <p
                            className="text-red incorrect f14-mvw text-start mb-0 mt-10"
                            style={{ color: "red" }}
                        >
                            This username has already been taken.
                        </p>
                        <p className="f14-mvw text-start">Please choose another one.</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserName;
