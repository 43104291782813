import React, { useState } from 'react'

const BodyType = () => {
    const [bodytype, setBodytype] = useState("");

    const handleBodyType = (value) => {
        setBodytype(value);
        console.log("Selected Body Type:", value);
      };

  return (
    <div>
       <div className="">
        <div className="font-m font-32 fw-medium text-center">What Best Describes Your Body Type?</div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
        Help us understand more about you by selecting the option that best matches your body type. 
        </div>
      </div> 
      <div className="bodytype-selection text-center">
        <h3 className="font-18 font-m fw-medium my-32">Choose Your Body Type</h3>
        <div className="btnsetAll btnsetAll2 mt-6">  
                <button
                className={`font-m border font-16 fw-normal ${bodytype === "Average" ? "selected" : ""}`}
                onClick={() => handleBodyType("Average")}>Average</button>
                    <button
                    className={`font-m border font-16 fw-normal ${bodytype === "Plus-size" ? "selected" : ""}`}
                    onClick={() => handleBodyType("Plus-size")}>Plus-size</button>
                        <button
                        className={`font-m border font-16 fw-normal ${bodytype === "Petite" ? "selected" : ""}`}
                        onClick={() => handleBodyType("Petite")}>Petite</button>
                            <button
                            className={`font-m border font-16 fw-normal ${bodytype === "Muscular" ? "selected" : ""}`}
                            onClick={() => handleBodyType("Muscular")}>Muscular</button>
                                <button
                                className={`font-m border font-16 fw-normal ${bodytype === "Broad" ? "selected" : ""}`}
                                onClick={() => handleBodyType("Broad")}>Broad</button>
                                    <button
                                    className={`font-m border font-16 fw-normal ${bodytype === "Lean" ? "selected" : ""}`}
                                    onClick={() => handleBodyType("Lean")}>Lean</button>
                                        <button
                                        className={`font-m border font-16 fw-normal ${bodytype === "Prefer not to say" ? "selected" : ""}`}
                                        onClick={() => handleBodyType("Prefer not to say")}>Prefer not to say</button>
        </div>
      </div>
    </div>
  )
}

export default BodyType
