import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ReplayIcon from '@mui/icons-material/Replay';

const PhotoUpload = () => {
    const [photos, setPhotos] = useState({
        primaryPhoto: null,
        publicPhotos: Array(4).fill(null),
        privatePhotos: Array(4).fill(null),
    });

    const handleFileChange = (event, photoType, index = null) => {
        const file = event.target.files[0];
        if (file) {
            if (photoType === 'primaryPhoto') {
                setPhotos(prevState => ({ ...prevState, primaryPhoto: file }));
            } else if (photoType === 'publicPhotos') {
                const updatedPublicPhotos = [...photos.publicPhotos];
                updatedPublicPhotos[index] = file;
                setPhotos(prevState => ({ ...prevState, publicPhotos: updatedPublicPhotos }));
            } else if (photoType === 'privatePhotos') {
                const updatedPrivatePhotos = [...photos.privatePhotos];
                updatedPrivatePhotos[index] = file;
                setPhotos(prevState => ({ ...prevState, privatePhotos: updatedPrivatePhotos }));
            }
        }
    };

    const handleDelete = (photoType, index) => {
        if (photoType === 'primaryPhoto') {
            setPhotos(prevState => ({ ...prevState, primaryPhoto: null }));
        } else if (photoType === 'publicPhotos') {
            const updatedPublicPhotos = [...photos.publicPhotos];
            updatedPublicPhotos[index] = null;
            setPhotos(prevState => ({ ...prevState, publicPhotos: updatedPublicPhotos }));
        } else if (photoType === 'privatePhotos') {
            const updatedPrivatePhotos = [...photos.privatePhotos];
            updatedPrivatePhotos[index] = null;
            setPhotos(prevState => ({ ...prevState, privatePhotos: updatedPrivatePhotos }));
        }
    };

    const handleLockPhoto = (index) => {
        const movedPhoto = photos.publicPhotos[index];
        const updatedPublicPhotos = [...photos.publicPhotos];
        updatedPublicPhotos[index] = null;

        const updatedPrivatePhotos = [...photos.privatePhotos];
        const emptyIndex = updatedPrivatePhotos.indexOf(null);
        if (emptyIndex !== -1) {
            updatedPrivatePhotos[emptyIndex] = movedPhoto;
        }

        setPhotos(prevState => ({
            ...prevState,
            publicPhotos: updatedPublicPhotos,
            privatePhotos: updatedPrivatePhotos,
        }));
    };

    const handleUnlockPhoto = (index) => {
        const movedPhoto = photos.privatePhotos[index];
        const updatedPrivatePhotos = [...photos.privatePhotos];
        updatedPrivatePhotos[index] = null;

        const updatedPublicPhotos = [...photos.publicPhotos];
        const emptyIndex = updatedPublicPhotos.indexOf(null);
        if (emptyIndex !== -1) {
            updatedPublicPhotos[emptyIndex] = movedPhoto;
        }

        setPhotos(prevState => ({
            ...prevState,
            publicPhotos: updatedPublicPhotos,
            privatePhotos: updatedPrivatePhotos,
        }));
    };

    const handleMakeProfile = (photo) => {
        setPhotos(prevState => ({ ...prevState, primaryPhoto: photo }));
    };

    const handleReupload = (photoType, index) => {
        document.getElementById(`${photoType}-${index}`).click();
    };

    const renderPhotoBox = (photo, onClick, type, index, size) => {
        return (
            <div
                className="photo-box"
                onClick={() => document.getElementById(`${type}-${index}`).click()}
                style={{
                    position: 'relative',
                    padding: '0px',
                    borderRadius: '9px',
                    margin: '0px',
                    display: 'inline-block',
                    width: size.width,
                    height: size.height,
                    cursor: 'pointer',
                    overflow: 'hidden',
                    background: '#EBEBEB',
                }}
            >
                {photo ? (
                    <img src={URL.createObjectURL(photo)} alt="Uploaded" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                    <div style={{ textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ color: 'rgba(196, 196, 196, 1)' }}><AddCircleOutlineIcon /></span>
                    </div>
                )}

                {photo && (
                    <div className='photoHoverEffect'>
                        <div
                            className="delete-icon"
                            onClick={(e) => { e.stopPropagation(); handleDelete(type, index); }}
                        >
                            <span style={{ fontSize: '11px' }}>X</span>
                        </div>

                        {type === 'privatePhotos' && (
                            <div
                                className="unlock-icon"
                                onClick={(e) => { e.stopPropagation(); handleUnlockPhoto(index); }}
                            >
                                <LockOpenIcon />
                            </div>
                        )}

                        {type === 'publicPhotos' && (
                            <div
                                className="lock-icon"
                                onClick={(e) => { e.stopPropagation(); handleLockPhoto(index); }}
                            >
                                <LockIcon />
                            </div>
                        )}

                        <div
                            className="reupload-icon"
                            onClick={(e) => { e.stopPropagation(); handleReupload(type, index); }}
                        >
                            <ReplayIcon />
                        </div>

                        {/* New Make Profile Button */}
                        <div
                            className="make-profile-icon"
                            onClick={(e) => { e.stopPropagation(); handleMakeProfile(photo); }}
                        >
                            Make Profile
                        </div>
                    </div>
                )}


                <input
                    id={`${type}-${index}`}
                    type="file"
                    accept="image/*"
                    onChange={event => handleFileChange(event, type, index)}
                    style={{ display: 'none' }}
                />
            </div>
        );
    };

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
            <div>
                <div className="font-m font-32 fw-medium text-center">Show Us Your Best Side!</div>
                <div className="font-m font-18 colorf5 pt-6 text-center">Upload at least 3 photos to complete your profile and give potential matches a glimpse of who you are. </div>
            </div>

            <h3 className="font-18 font-m fw-medium mt-24 mb-0">Minimum Selection: 3 Photos</h3>
            <p></p>
            <div className="d-flex gap-3">
                <div className="PrimaryPhoto">
                    <label style={{ color: '#3C4043', fontSize: '14px', fontWeight: 500 }}>Primary Photo</label>
                    <div style={{ display: 'flex', justifyContent: 'start', maxWidth: '300px', width: '100%' }}>
                        {renderPhotoBox(photos.primaryPhoto, handleFileChange, 'primaryPhoto', 0, { width: '200px', height: '266px' })}
                    </div>
                </div>

                <div className="text-start w-100">
                    <div className="publicPhotos">
                        <label style={{ color: '#3C4043', fontSize: '14px', fontWeight: 500 }}>Public Photos</label>
                        <div style={{ display: 'flex', justifyContent: 'start', gap: '10px' }}>
                            {photos.publicPhotos.map((photo, index) => renderPhotoBox(photo, handleFileChange, 'publicPhotos', index, { width: '89px', height: '121px' }))}
                        </div>
                    </div>
                    <div className="privatePhotos">
                        <label style={{ color: '#3C4043', fontSize: '14px', fontWeight: 500 }}>Upload Private Photos</label>
                        <div style={{ display: 'flex', justifyContent: 'start', gap: '10px' }}>
                            {photos.privatePhotos.map((photo, index) => renderPhotoBox(photo, handleFileChange, 'privatePhotos', index, { width: '89px', height: '121px' }))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PhotoUpload;
