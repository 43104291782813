import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const TillUsDOB = () => {
  const [formData, setFormData] = useState({
    birthdate: { day: '', month: '', year: '' },
  });

  // Handle input changes dynamically
  const handleInputChange = (type, value) => {
    setFormData((prevData) => ({
      ...prevData,
      birthdate: {
        ...prevData.birthdate,
        [type]: value.replace(/\D/g, ''), // Allow only numeric input
      },
    }));
  };

  // Calculate age based on the entered date
  const calculateAge = () => {
    const { day, month, year } = formData.birthdate;
    if (!day || !month || !year) return null; // Ensure all fields are filled

    const today = new Date();
    const birthDate = new Date(year, month - 1, day); // Months are 0-indexed
    let age = today.getFullYear() - birthDate.getFullYear();

    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  };

  const age = calculateAge();

  return (
    <div className="d-flex justify-content-center flex-column align-items-center p5vh h-100 text-center">
      <div className="font-m font-24 fw-medium">Tell Us Your Date of Birth?</div>
      <div className="font-m font-14 colorf5 pt-6">
        To ensure you meet our platform’s age requirements, please enter your date of birth.
      </div>

      <div className="dobSetnew d-flex justify-content-center gap-3" style={{ margin: '16px 0' }}>
        {['day', 'month', 'year'].map((type) => (
          <div key={type} className={type}>
            <input
              className="form-control font-m"
              type="number"
              placeholder={type === 'day' ? 'DD' : type === 'month' ? 'MM' : 'YYYY'}
              value={formData.birthdate[type]}
              onChange={(e) =>
                handleInputChange(type, e.target.value.slice(0, type === 'year' ? 4 : 2))
              }
              min={type === 'day' ? '1' : type === 'month' ? '1' : '1900'}
              max={
                type === 'day'
                  ? '31'
                  : type === 'month'
                  ? '12'
                  : new Date().getFullYear().toString()
              }
            />
          </div>
        ))}
      </div>

      {/* Age Validation and Eligibility Message */}
      {age !== null && (
        <div className="f16-mvw font-m">
          Your Age: {age}
          {age >= 18 ? (
            <div className="correct" style={{ color: '#02BC7D' }}>
              <CheckCircleIcon /> You are eligible to register on our platform.
            </div>
          ) : (
            <div className="incorrect" style={{ color: '#D7443E' }}>
              <CancelIcon /> You are not eligible to register on our platform.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TillUsDOB;
