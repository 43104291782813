import React from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import { Link } from 'react-router-dom';
const LinksYourAccount = ({ firstName, lastName, email, avatar, AccountTitle, AccountsubTitle }) => {
  // Generate initials from the first name and last name
  const getInitials = () => {
    const firstInitial = firstName?.[0]?.toUpperCase() || '';
    const lastInitial = lastName?.[0]?.toUpperCase() || '';
    return `${firstInitial}${lastInitial}`;
  }; 

  return (
    <>
    {/* Great! Let’s Link Your Account */}
    {/* Connect your account to get started quickly and securely. */}
     <div className=""> 
     <div className="font-m font-32 fw-medium text-center">{AccountTitle}</div>
      <div className="font-m font-14 colorf5 pt-6 text-center">
      {AccountsubTitle}
      </div>
     </div>
     
    <div className="btnsWithConnects gapset py-32 w-400-px mx-auto"> 
    <div className="d-flex align-items-center flex-column gapset">
    <div className="d-flex align-items-center justify-content-between gap-3 contWEmail borderSt height40 fontSt">
      <div className="d-flex align-items-center">
        <span className="d-flex align-items-center justify-content-center w-40-px h-40-px rounded-circle flex-shrink-0 overflow-hidden bg-secondary text-white fw-medium userimg">
          {avatar ? (
            <img
              src={avatar}
              alt="User Avatar"
              className="w-40-px h-40-px rounded-circle"
            />
          ) : (
            getInitials()
          )}
        </span>
        <div className="flex-grow-1 nameWemail text-start">
          <div className="font-12 mb-0 fw-medium text-start">{`Sign in as ${firstName}`}</div>
          <span className="font-12 text-secondary-light fw-medium text-start">{email}</span>
        </div>
      </div>
      <span className="text-primary-light text-md fw-medium google-iconEmail">
        <img
          src="/assets/images/google-icon-logo.svg"
          alt="Google Logo"
          className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden"
        />
      </span>
    </div>
        <button className="bg-black text-white contWapple font-12 fw-medium borderSt height40 fontSt d-flex align-items-center"><AppleIcon /> Continue with Apple Account</button>
        <button className="contWEmail2 font-12 fw-medium borderSt height40 fontSt">Continue with Email</button>
    </div> 
    </div>
    <div className="bylinkTp font-m text-center">
    By linking your account, you agree to our <spna className="d-block"><Link to="/">Terms</Link> and <Link to="/">Privacy Policy</Link>.</spna>
    </div>
    </>
  );
};

export default LinksYourAccount