import React from 'react' 
import VideoBanner from './Component/VideoBanner'
import WhySettle from './Component/WhySettle'
import RedefiningModern from './Component/RedefiningModern'
import Section3Slider from './Component/Section3Slider'
import LuxuryText from './Component/LuxuryText'
import YourExclusive from './Component/YourExclusive'
import HowLuxury from './Component/HowLuxury'
import SplideSlider from './Component/SplideSlider' 
import YourPrivacySec from './Component/YourPrivacy/YourPrivacySec'
import ReadyToExperience from './Component/ReadyToExperience'
import Footer from './Component/Footer/Footer'
import HeadNav from './HeadNav/HeadNav'
 

const HomePage = () => {
  return (
    <div>
      <HeadNav />
      <VideoBanner />
      <WhySettle />
      <RedefiningModern /> 
      <LuxuryText />
      <YourExclusive />
      <HowLuxury />
       <YourPrivacySec /> 
      <SplideSlider />
      <ReadyToExperience />
      <Footer /> 
    </div>
  )
}

export default HomePage
