import React, { useState } from "react";

const YourHobbies = () => {
  const [hobbies, setHobbies] = useState([]);
  
  // Define hobbies in an array
  const hobbiesList = [
    "Reading",
    "Traveling",
    "Cooking/Baking",
    "Hiking/Outdoor Adventures",
    "Photography",
    "Painting/Drawing",
    "Playing Sports",
    "Writing",
    "Yoga/Meditation",
    "Playing Musical Instruments",
    "Gardening",
    "Watching Movies/TV Shows",
    "Dancing",
    "Volunteering/Community Service",
    "Collecting (e.g., stamps, coins)"
  ];

  // Handle hobby selection
  const handleHobbiesSelection = (value) => {
    if (hobbies.includes(value)) {
      setHobbies(hobbies.filter((hobby) => hobby !== value));
    } else if (hobbies.length < 7) { // Limit to 7 hobbies
      setHobbies([...hobbies, value]);
    }
    console.log("Selected hobbies:", hobbies);
  };

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">What Are Your Hobbies? </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Select at least 3 hobbies that reflect your passions and lifestyle. Let’s connect you with others who share your interests and refined tastes. (Maximum 7 hobbies only)
        </div>
      </div>
      <div className="">
        <div className="interest-selection text-center">
          <h3 className="font-18 font-m fw-medium mt-24 mb-0">Choose Your Hobbies</h3>
          <div className="btnsetAll btnsetAll2 mt-6">
            {/* Dynamically render buttons */}
            {hobbiesList.map((hobby, index) => (
              <button
                key={index}
                className={`font-m border font-16 fw-normal ${hobbies.includes(hobby) ? "selected" : ""}`}
                onClick={() => handleHobbiesSelection(hobby)}
              >
                {hobby}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourHobbies