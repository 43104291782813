import React from 'react'

const FullName = () => {
  return (
    <div className="mw-425-px btnsWithConnects mx-auto">
      <div className="">
        <div className="font-m font-32 fw-medium text-center">What Is Your Full Name?</div>
        <div className="font-m font-14 colorf5 pt-6 text-center">Please enter your full name to personalize your experience.</div>
      </div>

      <input className="form-control mb-10 mt-32"
        type="text"
        placeholder="Enter Your Name"
        required
      />
    </div>
  )
}

export default FullName
