import React from 'react';

const YourPrivacyRight = ({ phoneImage, textImage }) => {
  return (
    <div className="phonescreenBg text-center">
      <div className="img">
        {/* Dynamic phone screen image */}
        <img src={phoneImage} className="img-fluid" alt="Phone screen" />
        <span className="img2">
          {/* Dynamic text image */}
          <img src={textImage} className="img-fluid" alt="Phone screen text" />
        </span>
      </div>
    </div>
  );
};

export default YourPrivacyRight
