import React from "react";
import Slider from "react-slick";
// import Slider from "react-slick";

const Section3Slider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slides = [
    {
      title: "LUXURY MATCHMAKING",
      description: "Real Profiles",
      img: "path_to_image1.jpg",
    },
    {
      title: "REAL PROFILES",
      description: "Exclusive Events",
      img: "path_to_image2.jpg",
    },
    {
      title: "DISCREET CONNECTIONS",
      description: "Luxury Matchmaking",
      img: "path_to_image3.jpg",
    },
    {
      title: "EXCLUSIVE EVENTS",
      description: "Discreet Connections",
      img: "path_to_image4.jpg",
    },
  ];

  return (
    <div className="slider-container" style={{ padding: "20px", background: "#000" }}>
      <h2 style={{ color: "#9f40ff", marginBottom: "20px" }}>Section 3 Slide</h2>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide-item">
            <img
              src={slide.img}
              alt={slide.title}
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "10px",
              }}
            />
            <h3 style={{ color: "#fff" }}>{slide.title}</h3>
            <p style={{ color: "#aaa" }}>{slide.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Section3Slider;
