import { Container } from '@mui/material';
import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {
  const socialLinks = [
    { icon: <FacebookIcon />, link: '#' },
    { icon: <XIcon />, link: '#' },
    { icon: <LinkedInIcon />, link: '#' },
    { icon: <InstagramIcon />, link: '#' },
    { icon: <YouTubeIcon />, link: '#' },
  ];

  const footerLinks = [
    { name: 'Home', link: '#' },
    { name: 'About Luxury Life', link: '#' },
    { name: 'Contact', link: '#' },
    { name: 'Features', link: '#' },
    { name: 'Membership Plans', link: '#' },
    { name: 'Privacy Policy', link: '#' },
  ];

  return (
    <div className="pt-110 pb-44 FooterSec">
      <Container maxWidth="xl">
        <div className="logoFooter text-center">
          <a href="#">
            <img src="/assets/images/footer-logo.svg" className="img-fluid" alt="logo" />
          </a>
        </div>

        <ul className="font-m font-16 fw-medium text-black d-flex justify-content-center align-items-center my-32 FooterLinks">
          {footerLinks.map((link, index) => (
            <li className="list-unstyled" key={index}>
              <a href={link.link} className="font-m font-16 fw-medium text-black py-6 px-20 text-decoration-none">
                {link.name}
              </a>
            </li>
          ))}
        </ul>

        <div className="d-flex justify-content-center align-items-center gap-5 FooterSocealMedia">
          <ul className="font-m font-16 fw-medium text-black d-flex justify-content-center align-items-center mb-0">
            {socialLinks.map((social, index) => (
              <li className="list-unstyled" key={index}>
                <a href={social.link} className="font-m font-16 fw-medium text-black py-6 px-8 text-decoration-none">
                  {social.icon}
                </a>
              </li>
            ))}
          </ul>

          <div className="d-flex align-items-center gap-4 text-nowrap fw-bold font-20 appFooter">
            Get the app!{' '}
            <a href="/" data-discover="true">
              <img src="/assets/images/google-play.png" className="img-fluid" alt="Google Play" />
            </a>
          </div>
        </div>

        <div className="Copyright text-center font-m font-16 fw-medium text-black mt-44">
          Copyright: © 2024 Luxurylife. All rights reserved.
        </div>
      </Container>
    </div>
  );
};

export default Footer;
