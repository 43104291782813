import { Container } from '@mui/material'; 
import React from 'react';
import { Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

const HowLuxury = () => {
  const featureData = [
    {
      title: 'Create Your Profile',
      description: 'Tell us who you are and what you\'re looking for.',
      icon: '/assets/images/create-your-profile-icon.svg', // Replace with the image URL
    },
    {
      title: 'Get Verified',
      description: 'Join a trusted community by confirming you\'re human through CAPTCHA, not a bot.',
      icon: '/assets/images/get-verified.svg', // Replace with the image URL
    },
    {
      title: 'Discover Matches',
      description: 'Our AI connects you with compatible partners who share your goals.',
      icon: '/assets/images/discover-matches-icon.svg', // Replace with the image URL
    },
    {
      title: 'Connect Privately',
      description: 'Start building meaningful relationships on your terms.',
      icon: '/assets/images/connect-privately.svg', // Replace with the image URL
    },
  ];

  const FeatureItem = ({ title, description, icon }) => (
    <div className="col-sm-6 col-md-6 col-lg-3">
    <div className="feature-item text-center">
      <span className="img"><img src={icon} alt={title} className="feature-icon" /></span>
      <h3 className="text-white font-m font-32 fw-medium mt-16">{title}</h3>
      <p className="text-white font-m font-16 fw-normal">{description}</p>
    </div>
    </div>
  );

  return ( 
    <div className="mainHowLuxuryBg HowLuxury-Sec parallaxBg">
      <Container maxWidth="xl" className="mainHowLuxuryBgc text-center">
        <h2 className="text-white font-m fw-bold mb-32">How Luxury Life Works</h2>
        <div className="features-container row"> 
          {featureData.map((feature, index) => (
            <FeatureItem
              key={index}
              title={feature.title}
              description={feature.description}
              icon={feature.icon}
            />
          ))} 
           
          <div className="mt-32"><Button
       className="btn gradient-btn font-20 fw-bold font-m text-black rounded-pill py-10 px-20"
      endIcon={<EastIcon />}  // Place the icon before the text
    >
     Join Now and Discover the Luxury of True Connections
    </Button></div>
        </div>
      </Container>
    </div> 
  );
};

export default HowLuxury
