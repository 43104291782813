import { Container, Typography } from '@mui/material';
import React from 'react';

const RedefiningModern = () => {
  // Dynamic content stored in an object
  const content = {
    title: 'Redefining Modern Relationships',
    description:
      'LuxuryLife bridges the gap between ambition and affluence, creating meaningful connections for those who value beauty, success, and discretion.',
    imageData: [
      {
        imgSrc: '/assets/images/510db8d4ce-1733210349974.png',
        details:
          'Designed for successful individuals and aspirational partners seeking more than just fleeting interactions.',
      },
      {
        imgSrc: '/assets/images/35657a20e1-1733210349721.png',
        details:
          'Built with advanced privacy features to ensure your personal life remains personal.',
      },
    ],
  };

  return (
    <div style={{ background: '#000' }} className="txtredbg goldshadow Redefining-Sec pb-44">
      <Container maxWidth="xl">
        <div className="row">
          <div className="d-flex justify-content-between align-items-center gap-4 mb-32 RedefiningHding">
            <h2 className="text-white font-m fw-bold mw-500-px w-100">
              <span className="font-p font-64 fst-italic text-g fw-bold">Redefining</span>{' '}
              Modern Relationships
            </h2>
            <p className="text-white font-20 fw-normal">{content.description}</p>
          </div>

          {/* Dynamically rendering images and details */}
          <div className="redefImgbg Scroll-none d-flex gap-4 mt-16">
            {content.imageData.map((item, index) => (
              <div key={index} className="redefImg w-50 rounded-4 overflow-hidden">
                <span className="img">
                  <img src={item.imgSrc} className="img-fluid" alt="" />
                </span>
                <div className="details font-32 font-m text-white p-44">{item.details}</div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RedefiningModern
