import React from 'react';

const YourPrivacy = () => {
  // Data for each section
  const privacyItems = [
    {
      icon: '/assets/images/lock-icon.svg',
      text: 'Your data is protected with industry-leading encryption.',
    },
    {
      icon: '/assets/images/toggle-icon.svg',
      text: 'Customizable privacy settings ensure your profile is only visible to those you choose.',
    },
    {
      icon: '/assets/images/star-icon.svg',
      text: 'Over 90% of our users rate their experience as premium.',
    },
    {
      icon: '/assets/images/globe.svg',
      text: 'Our community includes executives, entrepreneurs, and aspirational individuals from over 20 countries.',
    },
  ];

  return (
    <div>
      <h2 className="font-m fw-bold text-white mb-32">Your <span className="font-p font-64 fst-italic">Privacy</span>, Our Priority</h2>
      {privacyItems.map((item, index) => (
        <div
          key={index}
          className="yourPrivacyText d-flex gap-3 align-items-center py-16"
        >
          <span className="img">
            <img src={item.icon} className="img-fluid" alt="" />
          </span>
          <p className="font-20 font-m fw-normal text-white mb-0">{item.text}</p>
        </div>
      ))}
    </div>
  );
};

export default YourPrivacy
