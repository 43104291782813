import React, { useState } from 'react';

const DoYouSmoke = () => {
  const [youSmoke, setYouSmoke] = useState("");

  // Define the options dynamically
  const options = ["Yes", "No", "Prefer not to say"];

  const handleYouSmoke = (value) => {
    setYouSmoke(value);
    console.log("Selected Option:", value);
  };

  return (
    <div>
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Do You Smoke? </div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          Please let us know if you smoke so we can better match you with individuals who share similar lifestyle choices.
        </div>
      </div>
      <div className="YouSmoke-selection text-center">
        <h3 className="font-18 font-m fw-medium mt-24 mb-0">Select Your Choice</h3>
        <div className="btnsetAll btnsetAll2 mt-6">
          {/* Dynamically render buttons */}
          {options.map((option, index) => (
            <button
              key={index}
              className={`font-m border font-16 fw-normal ${youSmoke === option ? "selected" : ""}`}
              onClick={() => handleYouSmoke(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoYouSmoke;
